<template>
  <div @click="$emit('close')" class="outer flex items-center justify-center">
    <div @click.stop class="inner w-1/3 relative" :class="bgClass">
      <button @click="$emit('close')" class="rounded-full bg-white shadow absolute right-0 -mt-3 -mr-3 focus:outline-none z-10">
        <icon name="close" class="w-8 h-8" />
      </button>
      <div class="w-full h-full" :class="overflowClass">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalSmall',
  props:{
    bgClass:{
      default: 'bg-white'
    },
    overflowClass: {
      default: 'overflow-y-scroll'
    }
  },
}
</script>

<style scoped>
.outer {
  @apply z-50;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
}

.inner {
  @apply rounded;
}


</style>
