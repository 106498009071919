import Vue from 'vue'
import FormGroup from './components/FormGroup'
import Panel from './components/Panel'
import SelectWrapper from './components/SelectWrapper'
import TableWrapper from './components/TableWrapper'
import Loader from './components/Loader'
import LoaderContainer from './components/LoaderContainer'
import Icon from './components/Icon'
import DatePickerComponent from "./components/DatePickerComponent";

Vue.component('icon', Icon)
Vue.component('form-group', FormGroup)
Vue.component('panel', Panel)
Vue.component('select-wrapper', SelectWrapper)
Vue.component('table-wrapper', TableWrapper)
Vue.component('loader', Loader)
Vue.component('loader-container', LoaderContainer)
Vue.component('date-picker-input', DatePickerComponent)
