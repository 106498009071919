<template>
  <div :class="[className, right ? 'datetime-picker-right' : null]">
    <div class="flex flex-wrap -mx-2">
      <div class="flex-grow px-2">
        <label v-if="label" class="block mb-2">{{ label }}</label>
      </div>
      <div class="flex-shrink px-2">
        <tooltip v-if="tooltip">
          {{ tooltip }}
        </tooltip>
      </div>
    </div>
    <date-time-picker
      v-model="content"
      @input="handleInput"
      @close="$emit('close')"
      :format="format"
      :value-format="valueFormat"
      :constraints-format="valueFormat"
      main-button-class="w-full py-2 px-3 border-2 rounded bg-white outline-none focus:border-primary-500 text-left"
      :min-date="minDate"
      :max-date="maxDate"
      :minute-step="5"
      :disabled="disabled"
      :only-time-picker="time && !date"
      :time-picker="time"
      :today-button="todayButton"
      :clear-button="clearButton && content"
      :empty-value="null"
      :auto-close="autoClose"
      value-type="String"
    >
      <template v-slot:choose-date>
        <span class="text-gray-400 whitespace-no-wrap">{{ placeholder }}</span>
      </template>
      <template v-slot:date>
        <button class="bg-primary-500 rounded py-2 px-4 text-white">
          <i class="fas fa-arrow-left"></i> Set Date
        </button>
      </template>
      <template v-slot:time>
        <button class="bg-primary-500 rounded py-2 px-4 text-white">
          Set Time <i class="fas fa-arrow-right"></i>
        </button>
      </template>
      <template v-slot:today>
        <button class="bg-gray-200 rounded py-2 px-4 text-black" @click="setNowTime">
          Set to Today
        </button>
      </template>
      <template v-slot:clear>
        <button class="bg-gray-200 rounded py-2 px-4 text-black">
          Clear
        </button>
      </template>
    </date-time-picker>
    <div v-if="error" class="text-red-600 text-sm mt-2 font-bold">
      <i class="fas fa-exclamation-triangle"></i>
      {{ error }}
    </div>

  </div>
</template>

<script>
import randomstring from 'randomstring'
import moment from 'moment'
import DateTimePicker from 'vue-vanilla-datetime-picker'
export default {
  components: {
    DateTimePicker
  },
  props: {
    className: {
      type: String,
      default: 'mb-6'
    },
    label: {
      type: String
    },
    error: {
      type: String
    },
    value: {
    },
    placeholder: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String
    },
    minDate: {
      type: String,
      default: null
    },
    maxDate: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    date: {
      type: Boolean,
      default: true
    },
    time: {
      type: Boolean,
      default: false
    },
    todayButton: {
      type: Boolean,
      default: false
    },
    clearButton: {
      type: Boolean,
      default: false
    },
    autoClose: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: randomstring.generate(),
      content: this.value
    }
  },
  computed: {
    format () {
      if (this.date && this.time) {
        return 'dd/LL/yyyy HH:mm'
      }
      if (this.time) {
        return 'HH:mm'
      }
      return 'dd/LL/yyyy'
    },
    valueFormat () {
      if (this.date && this.time) {
        return 'yyyy-LL-dd HH:mm:ss'
      }
      if (this.time) {
        return 'HH:mm:ss'
      }
      return 'yyyy-LL-dd'
    }
  },
  methods: {
    handleInput () {
      this.$emit('input', this.content)
      this.$emit('change')
    },
    setNowTime () {
      setTimeout(() => {
        if (!this.time || !this.content) {
          return
        }
        let now = moment()
        this.content = moment(this.content).hour(now.hour()).minute(now.minute()).format('YYYY-MM-DD HH:mm:ss')
      }, 250)
    }
  },
  watch: {
    value: function () {
      this.content = this.value
    }
  }
}
</script>
